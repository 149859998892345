<template>
  <div>
    <!-- ========== HEADER ========== -->
    <div id="header">
      <b-navbar toggleable="lg" variant="white" fixed="top" class="shadow-sm">
        <b-navbar-brand href="/">
          <b-img :src="require('../assets/images/logo/official.png')" fluid width="150" alt="logo" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">
          <template>
            <font-awesome-icon :icon="['fas', 'bars']" />
          </template>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item @click="goAnchor('#home')">主页</b-nav-item>
            <b-nav-item @click="goAnchor('#about')">公司介绍</b-nav-item>
            <b-nav-item @click="goAnchor('#service')">主营业务</b-nav-item>
            <b-nav-item @click="goAnchor('#product')">海外社群</b-nav-item>
            <b-nav-item @click="goAnchor('#founding-team')">创始团队</b-nav-item>
            <b-nav-item @click="goAnchor('#social-recognition')">社会认可</b-nav-item>
            <b-nav-item @click="goAnchor('#partners')">合作品牌</b-nav-item>
            <b-nav-item @click="goAnchor('#contact')">联系我们</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <!-- ========== END HEADER ========== -->
    <!-- ========== MAIN CONTENT ========== -->
    <b-container fluid="true">
      <!-- ========== HOME PAGE ========= -->
      <div id="home">
        <div class="mask">
          <div :class="['bg-img', {'bg-img-fixed': !isIos()}]" />
        </div>
        <div class="intro text-white mt-5 pt-5">
          <h1 class="animate__animated animate__fadeInLeft font-weight-bolder" style="font-size: 2.4rem; letter-spacing: 2px;">杭州弧米科技有限公司欢迎你</h1>
          <h1 class="animate__animated animate__fadeInLeft font-weight-bold" style="font-size: 2.4rem;">WELCOME TO HOMIE TECH</h1>
          <b-button
            class="px-3 py-2 mt-3 animate__animated animate__fadeInRight"
            variant="primary"
            size="lg"
            @click="goAnchor('#about')"
          >
            了解我们
          </b-button>
        </div>
      </div>
      <!-- ========== END HOME PAGE ========= -->
      <!-- ========== ABOUT ========= -->
      <div id="about" class="bg-light">
        <div class="box" />
        <h2 class="mt-5 pt-5 text-center font-weight-bold">公司介绍</h2>
        <b-container class="mt-5">
          <div class="text-left pb-5 text-about">
            杭州弧米科技有限公司作为一家“互联网+”跨境企业服务商，我们的使命是“吸引全球智慧来华、助力中国品牌出海”。通过打造“人才+政策+产业”的生态闭环，帮助更多中国企业货通全球。公司发挥丝路天使的双重语言、文化、政治等优势，通过多种新媒体营销方式助力中国品牌出海，实现人才和产业的对接落地，主要业务有跨境直播代运营、跨境直播达人营销、跨境直播培训、跨境直播大赛及海外短视频拍摄、外籍平面拍摄、小语种翻译等。
          </div>
        </b-container>
      </div>
      <!-- ========== END ABOUT ========= -->
      <!-- ========== SERVICE ========= -->
      <div id="service">
        <div class="box" />
        <h2 class="mt-5 pt-5 text-center font-weight-bold">主营业务</h2>
        <b-container class="my-5">
          <!-- ========== SERVICE1 ========= -->
          <b-row class="mx-auto" align-h="between">
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'service1')
              }"
              lg="5"
              :class="{
                'visible animate__animated animate__fadeInLeft':
                  showAnimationFor.service1,
                invisible: !showAnimationFor.service1
              }"
            >
              <b-img :src="require('@/assets/images/service/live.jpg')" fluid rounded="lg" />
            </b-col>
            <b-col
              v-observe-visibility="{callback: (isVisible, entry) =>isViewableNow(isVisible, entry, 'service1')}"
              lg="6"
              class="my-5"
              :class="{'visible animate__animated animate__fadeInRight':showAnimationFor.service1,
                       invisible: !showAnimationFor.service1}"
            >
              <div class="px-3 px-lg-0">
                <div class="text-left pb-3">
                  <font-awesome-icon :icon="['fas', 'handshake']" size="2x" />
                  <span class="h4 font-weight-bold ml-3">跨境直播代运营</span>
                </div>
                <p class="text-left service-text text-secondary">
                  HomieLive作为公司旗下的核心出海品牌，通过全球外语网红带货直播助力中国品牌出海。直播基地位于杭州，为速卖通、国际站、TikTok、Shopee、Lazada等平台官方认证机构，目前已在多平台完成了数千场多语种、多品类直播，为国货出海创造新的机遇。
                </p>
                <b-row>
                  <b-col>
                    <b-button variant="primary" @click="jump('https://fuwu.alibaba.com/product/buy.htm?spm=a271b.7755856.0.0.37183927xcwSe8&code=ISVXX8D0E93')">国际站上门直播</b-button>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="jump('https://fuwu.alibaba.com/product/buy.htm?spm=a271b.7755856.0.0.37183927xcwSe8&code=ISVXX866032')">国际站寄件直播</b-button>
                  </b-col>
                  <b-col>
                    <b-button v-b-modal.otherLivePreview variant="primary"><span>其 他 直 播</span></b-button>
                  </b-col>
                </b-row>
              </div>
              <b-modal id="otherLivePreview" body-class="p-0" hide-header hide-footer>
                <b-img :src="require('@/assets/images/service/1.png')" fluid-grow />
              </b-modal>
            </b-col>
          </b-row>
          <!-- ========== END SERVICE1 ========= -->
          <!-- ========== SERVICE2 ========= -->
          <b-row class="mx-auto mt-5" align-h="between">
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'service2')
              }"
              lg="6"
              order-lg="2"
              :class="{
                'visible animate__animated animate__fadeInRight':
                  showAnimationFor.service2,
                invisible: !showAnimationFor.service2
              }"
            >
              <b-img :src="require('@/assets/images/service/2.jpg')" fluid rounded="lg" />
            </b-col>
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'service2')
              }"
              lg="6"
              class="my-5"
              :class="{
                'visible animate__animated animate__bounceInLeft':
                  showAnimationFor.service2,
                invisible: !showAnimationFor.service2
              }"
            >
              <div class="px-3 px-lg-0">
                <div class="text-left pb-3">
                  <font-awesome-icon :icon="['fas', 'trophy']" size="2x" />
                  <span class="h4 font-weight-bold ml-3">跨境直播大赛</span>
                </div>
                <p class="text-left service-text text-secondary">
                  跨境电子商务已成为外贸稳增长的新动能，大众创业、万众创新的新热土。外国留学生、海归人才具有了解中国，熟悉当地国的文化、政治、 社会和语言等优势，是跨境电商的“丝路天使”。为深入推进“一带一路”建设，搭建“人才+项目+产业”社会资源对接平台，在中国（杭州） 跨境电商综合试验区、阿里巴巴等相关单位的联合支持下，我公司连续承办两届“扬帆起杭”全球青年跨境电商创新创业大赛，并成为第十二届全国大学生三创赛跨境直播实战赛承办单位。
                </p>
                <b-button variant="primary" @click="jump('http://www.sailfromhangzhou.com/')">了解详情</b-button>
              </div>
            </b-col>
          </b-row>
          <!-- ========== END SERVICE2 ========= -->
          <!-- ========== SERVICE3 ========= -->
          <b-row class="mx-auto mt-5" align-h="between">
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'service3')
              }"
              lg="5"
              :class="{
                'visible animate__animated animate__fadeInLeft':
                  showAnimationFor.service3,
                invisible: !showAnimationFor.service3
              }"
            >
              <b-img :src="require('@/assets/images/service/3.jpg')" fluid rounded="lg" />
            </b-col>
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'service3')
              }"
              lg="6"
              class="my-5"

              :class="{
                'visible animate__animated animate__fadeInRight':
                  showAnimationFor.service3,
                invisible: !showAnimationFor.service3
              }"
            >
              <div class="px-3 px-lg-0">
                <div class="text-left pb-3">
                  <font-awesome-icon :icon="['fas', 'graduation-cap']" size="2x" />
                  <span class="h4 font-weight-bold ml-3">跨境直播培训</span>
                </div>
                <p class="text-left service-text text-secondary">
                  公司联合数十所高校共同建立跨境主播人才培养和孵化体系，致力于打造国内最大的跨境主播商学院。目前已组织2022首期跨境直播师资培训营、“粤品出海”跨境主播培训营等品牌项目，并受浙江省商务厅邀请向来自全省的200余名跨境电商师资培训营学员进行跨境直播模块授课。
                </p>
                <b-button variant="primary" @click="jump('https://college.homielive.com/')">了解详情</b-button>
              </div>
            </b-col>
          </b-row>
          <!-- ========== END SERVICE3 ========= -->
          <!-- ========== SERVICE4 ========= -->
          <b-row class="mx-auto mt-5" align-h="between">
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'service2')
              }"
              lg="5"
              order-lg="2"
              :class="{
                'visible animate__animated animate__fadeInRight':
                  showAnimationFor.service4,
                invisible: !showAnimationFor.service4
              }"
            >
              <b-img :src="require('@/assets/images/service/4.jpg')" fluid rounded="lg" />
            </b-col>
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'service4')
              }"
              lg="6"
              class="my-5"
              :class="{
                'visible animate__animated animate__fadeInLeft':
                  showAnimationFor.service4,
                invisible: !showAnimationFor.service4
              }"
            >
              <div class="px-3 px-lg-0">
                <div class="text-left pb-3">
                  <font-awesome-icon :icon="['fas', 'user-graduate']" size="2x" />
                  <span class="h4 font-weight-bold ml-3">跨境直播人才输送</span>
                </div>
                <p class="text-left service-text text-secondary">
                  作为TikTok Shop外语主播培训服务商，公司联合政府、高校和平台打造跨境主播人才培养和输送项目，结合全国产业带需求，进行定制化的人才培养和输送。
                </p>
                <b-button variant="primary" @click="jump('https://talent.homielive.com/')">了解详情</b-button>
              </div>
            </b-col>
          </b-row>
          <!-- ========== END SERVICE4 ========= -->
          <!-- ========== SERVICE5 ======== -->
          <b-row class="mx-auto mt-5" align-h="between">
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'service2')
              }"
              lg="5"
              :class="{
                'visible animate__animated animate__fadeInRight':
                  showAnimationFor.service4,
                invisible: !showAnimationFor.service4
              }"
            >
              <b-img :src="require('@/assets/images/service/5.jpg')" fluid rounded="lg" />
            </b-col>
            <b-col
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  isViewableNow(isVisible, entry, 'service4')
              }"
              lg="6"
              class="my-5"
              :class="{
                'visible animate__animated animate__fadeInLeft':
                  showAnimationFor.service4,
                invisible: !showAnimationFor.service4
              }"
            >
              <div class="px-3 px-lg-0">
                <div class="text-left pb-3">
                  <font-awesome-icon :icon="['fas', 'server']" size="2x" />
                  <span class="h4 font-weight-bold ml-3">独立站建站</span>
                </div>
                <p class="text-left service-text text-secondary">
                  对于出海品牌来说，跨境电商独立站能更自由地做个性化运营，实现二次转化，提高消费者品牌忠诚度和品牌溢价转化率，“TikTok+独立站”，“外贸+独立站”成为新趋势，弧米独立站服务为你创造新的可能！
                </p>
                <b-button variant="primary" @click="jump('https://dtc.homielive.com/')">了解详情</b-button>
              </div>
            </b-col>
          </b-row>
          <!-- ========== END SERVICE5 ======== -->
        </b-container>
      </div>
      <!-- ========== END SERVICE ========= -->
      <!-- ========== PRODUCT ========= -->
      <div id="product">
        <div class="box" />
        <h2 class="mt-5 pt-5 text-center font-weight-bold">海外社群</h2>
        <b-container>
          <b-row class="mx-3 mt-5" align-h="center">
            <b-col class="my-3" lg="4">
              <el-card :body-style="{ padding: 0 }">
                <b-img :src="require('@/assets/images/product/wechat.jpg')" fluid />
                <hr>
                <div class="px-3 py-3">
                  <h5>微信公众号</h5>
                  <p class="text-center">
                    发布文章2000+<br>
                    翻译字数100万+
                  </p>
                </div>
              </el-card>
            </b-col>
            <b-col class="my-3" lg="4">
              <el-card :body-style="{ padding: '0px' }">
                <b-img :src="require('@/assets/images/product/service.jpg')" fluid />
                <hr>
                <div class="px-3 py-3">
                  <h5>信息发布社区</h5>
                  <p class="text-center">
                    发布各类帖子10000+<br>
                    各类活动500+
                  </p>
                </div>
              </el-card>
            </b-col>
            <b-col class="my-3" lg="4">
              <el-card :body-style="{ padding: '0px' }">
                <b-img :src="require('@/assets/images/product/ss.jpg')" fluid />
                <hr>
                <div class="px-3 py-3">
                  <h5>职场小程序</h5>
                  <p class="text-center" style="text-indent: 2rem">
                    人才招聘、小语种翻译、网红直播、签证代办、课程培训
                  </p>
                </div>
              </el-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- ========== END PRODUCT ========= -->
      <!-- ========== INTRO ========= -->
      <div id="intro" class="bg-light">
        <b-container class="pt-5 pb-5 text-left text-about">
          作为所有跨境业务的基础，公司拥有国内最大的来华外国留学生社群MyHomie。MyHomie致力于为在华外国留学生提供分类资讯和本地化信息服务，并通过建立圈子化社区，满足他们在生活、学习、社交、职业发展等多方面的需求，真正做到连接中国社会。目标是成为在华外籍人士的生活宝典，并打造国内最大的国际人才智库，目前平台已有来自全球各个国家的外国留学生用户四万余名。
        </b-container>
      </div>
      <!-- ========== END INTRO ========= -->
      <!-- ========== FOUNDING TEAM-->
      <div id="founding-team">
        <div class="box" />
        <h2 class="mt-5 pt-5 font-weight-bold">创始团队</h2>
        <b-container>
          <b-row>
            <b-col lg="4" class="my-3">
              <el-card body-style="padding: 0; min-height: 700px;">
                <b-img :src="require('@/assets/images/foundingTeam/jordan.jpg')" fluid />
                <div>
                  <b-row class="mt-3">
                    <b-col cols="4" class="font-weight-bold">邓亚辉</b-col>
                    <b-col class="text-secondary" style="font-size: 15px;">创始人兼CEO</b-col>
                  </b-row>
                  <b-row class="mt-3 title-info px-5 text-left">
                    达沃斯论坛全球杰出青年杭州社区成员<br>
                    团中央TOP20“一带一路”青年创业故事入选者<br>
                    第十二届全国大学生三创赛“跨境电商实战赛道”工作委员会委员<br>
                    浙江省大学生“互联网+”创新创业大赛评委<br>
                    杭州市公安局出入境管理局特约监督员<br>
                    浙江工业大学等学校创业导师<br>
                    长三角G60科创大走廊人才峰会特邀嘉宾<br>
                  </b-row>
                </div>
              </el-card>
            </b-col>
            <b-col lg="4" class="my-3">
              <el-card body-style="padding: 0; min-height: 700px;">
                <b-img :src="require('@/assets/images/foundingTeam/jinzi.jpg')" fluid />
                <div>
                  <b-row class="mt-3">
                    <b-col cols="4" class="font-weight-bold">徐晋子</b-col>
                    <b-col class="text-secondary" style="font-size: 15px;">B2C跨境直播运营总监</b-col>
                  </b-row>
                  <b-row class="mt-3 title-info px-5 text-left">
                    毕业于美国纽约州立时装技术学院<br>
                    TikTok、速卖通等主流跨境电商平台1000+场直播实操经验<br>
                    阿里巴巴国际站浙江大区、南粤大区等受邀讲师<br>
                    阿里巴巴国际站浙江大区多媒体明星赛决赛评委导师<br>
                    全国大学生“三创赛”跨境直播实战赛总决赛评委<br>
                  </b-row>
                </div>
              </el-card>
            </b-col>
            <b-col lg="4" class="my-3">
              <el-card body-style="padding: 0; min-height: 700px;">
                <b-img :src="require('@/assets/images/foundingTeam/Lu.jpg')" fluid />
                <div>
                  <b-row class="mt-3">
                    <b-col cols="4" class="font-weight-bold">陆颖</b-col>
                    <b-col class="text-secondary" style="font-size: 15px;">B2B跨境直播运营总监</b-col>
                  </b-row>
                  <b-row class="mt-3 title-info px-5 text-left">
                    毕业于西班牙瓦亚伦大学管理学硕士<br>
                    阿里巴巴国际站平台1000+场直播实操经验<br>
                    首届“跨境商贸直通车‘全国高校跨境电商创业直播大赛项目负责人’”<br>
                    九月采购节、三月新贸节等多次大型直播项目运营负责人<br>
                  </b-row>
                </div>
              </el-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- ========== END FOUNDING TEAM-->
      <!-- ========== SOCIAL RECOGNITION ========= -->
      <div id="social-recognition">
        <div class="box" />
        <h2 class="mt-5 pt-5 text-center font-weight-bold">社会认可</h2>
        <b-container>
          <carousel class="my-5" :list="recognitionList[0]">
            <template v-slot:body="{ item }">
              <div class="shadow" style="min-height: 350px">
                <b-img :src="item.src" fluid class="w-100" />
                <div class="px-3 py-3 pt-lg-5 text-secondary">{{ item.text }}</div>
              </div>
            </template>
          </carousel>
          <carousel class="my-5" :list="recognitionList[1]">
            <template v-slot:body="{ item }">
              <div class="shadow" style="min-height: 350px">
                <b-img :src="item.src" fluid class="w-100" />
                <div class="px-3 py-3 pt-lg-5 text-secondary">{{ item.text }}</div>
              </div>
            </template>
          </carousel>
        </b-container>
      </div>
      <!-- ========== END SOCIAL RECOGNITION ========= -->
      <!-- ========== PARTNERS ========= -->
      <div id="partners" class="pb-5">
        <div class="box" />
        <h2 class="mt-5 pt-5 text-center font-weight-bold">合作品牌</h2>
        <b-container>
          <b-img :src="require('@/assets/images/partners.png')" class="my-1" fluid />
        </b-container>
      </div>
      <!-- ========== PARTNERS ========= -->
      <!-- ========== CONTACT ========= -->
      <div id="contact">
        <div class="box" />
        <h2 class="mt-5 pt-5 text-center font-weight-bold">联系我们</h2>
        <b-container>
          <b-row class="mx-auto">
            <b-col class="mx-auto" cols="12" lg="6">
              <b-row>
                <b-col class="border-success">
                  <b-img :src="require('@/assets/images/QR/wechat_person.png')" fluid />
                  <p class="text-secondary" style="font-size: 0.9rem">客服微信</p>
                </b-col>
                <b-col class="border-success">
                  <b-img :src="require('@/assets/images/QR/official_video.jpg')" fluid />
                  <p class="text-secondary" style="font-size: 0.9rem">官方视频号</p>
                </b-col>
                <b-col>
                  <b-img :src="require('@/assets/images/QR/official_douyin.jpg')" fluid />
                  <p class="text-secondary" style="font-size: 0.9rem">官方抖音号</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- ========== END CONTACT ========= -->
    </b-container>
    <!-- ========== END MAIN CONTENT ========== -->
    <!-- ========== FOOTER ========== -->
    <footer class="pt-3">
      <b-container class="pt-5">
        <hr>
        <b-row class="mb-3 pt-3 text-secondary">
          <b-col lg="3" class="text-left" style="line-height: 2rem">
            <h4 class="font-weight-bold text-dark">使命</h4>
            <p class="my-3">吸引全球智慧来华，助力中国品牌出海</p>
            <h4 class="font-weight-bold text-dark">价值观</h4>
            <p class="my-3">拥抱变化 开放包容 主动协作 激情活力 始终创业</p>
            <h4 class="font-weight-bold text-dark">愿景</h4>
            <p class="my-3">跨境无界</p>
          </b-col>
          <b-col lg="5" class="text-left">
            <h4 class="font-weight-bold text-dark">联系方式</h4>
            <ol class="pl-0" style="line-height: 3rem">
              <li>
                <span class="font-weight-bold">电话：</span>
                17816876961
              </li>
              <li>
                <span class="font-weight-bold">邮箱：</span>
                homietechx@163.com
              </li>
              <li>
                <span class="font-weight-bold">地址：</span>
                <div>杭州市上城区九环路9号浙江省国家大学科技园B512室</div>
                <div>杭州市萧山区市心北路2000号ITC归谷国际中心5楼</div>
              </li>
              <li>
                <span class="font-weight-bold">邮编：</span>
                310026
              </li>
            </ol>
          </b-col>
          <b-col lg="4" class="text-left">
            <p class="font-weight-bold">地址</p>
            <baidu-map
              class="bm-view"
              :center="map.center"
              :zoom="map.zoom"
              :scroll-wheel-zoom="true"
            >
              <bm-info-window
                :position="map.center"
                :title="map.window.title"
                :content="map.window.contents"
                :show="map.window.show"
              >
                <p v-text="map.window.contents" />
              </bm-info-window>
            </baidu-map>
          </b-col>
        </b-row>
      </b-container>
      <div class="text-center mb-3" style="font-size: 0.6rem">©2021 弧米科技
        <a href="https://beian.miit.gov.cn/" class="text-secondary" target="_blank">
          浙ICP备17057855号-8
        </a>
      </div>
    </footer>
    <!-- ========== END FOOTER ========== -->
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from '@/components/Carousel'

export default {
  name: 'Home',
  components: {
    Carousel
  },
  data() {
    return {
      showAnimationFor: {
        service1: false,
        service2: false,
        service3: false,
        service4: false
      },
      service1SrcList: [
        require('@/assets/images/service/1.png')
      ],
      map: {
        center: { lng: 120.261263, lat: 30.317485 },
        zoom: 16,
        window: {
          title: '详细地址',
          show: true,
          contents: '浙江省国家大学科技园1栋B幢512室'
        }
      },
      recognitionList: [[], []],
      recognitionText: [
        '被评为DISCOVERY HANGZHOU丝路天使直播季官方直播服务商',
        '江干区人社局朱红局长来访公司',
        '举办“扬帆起杭”全球青年跨境电商创业创新大赛',
        '举办2019HINEW杭州国际众创大会',
        '荣获2020杭州人力资源服务和产品创新路演十佳',
        '荣获2020年度贡献力十佳创服机构',
        '荣获上城公民警校“2018年最佳合作单位”',
        '受邀参加广东国际商机推介会',
        '受邀参加国际数字教育展',
        '受邀参加云南湄澜合作博览会',
        '受邀参加中国（杭州）数字贸易生态峰会',
        '受邀出席加纳、肯尼亚、赞比亚、喀麦隆四国驻华大使馆联合活动',
        '印度尼西亚驻华大使馆教育参赞Priyanto Wibowo先生',
        'TikTok跨境直播桐庐培训营',
        '国际站三月新贸节千人培训峰会',
        '举办“扬帆起杭”第二届全球青年跨境电商创业创新大赛',
        '受邀参加广东跨境电商博览会',
        '受邀向浙江省跨境电商师资培训班授课',
        '共建温州大学跨境直播人才孵化实践基地',
        '旗下主播获得Lazada2021跨境直播大赛最佳带货能力奖',
        '2022全国大学生三创赛总决赛分享',
        '国际站行业达人创作者计划成立仪式'
      ],
      partners: []
    }
  },
  created() {
    this.initRecognition()
  },
  methods: {
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      })
    },
    isViewableNow(isVisible, entry, section) {
      if (isVisible === false) {
        return
      }
      this.showAnimationFor[section] = isVisible
    },
    jump(link) {
      window.open(link, '_blank')
    },
    initRecognition() {
      for (let i = 0; i < 22; i++) {
        const imgIndex = i + 1
        if (i < 11) {
          this.recognitionList[0].push({
            id: i,
            src: require('@/assets/images/recognition/' + imgIndex + '.jpg'),
            text: this.recognitionText[i]
          })
        } else {
          this.recognitionList[1].push({
            id: i,
            src: require('@/assets/images/recognition/' + imgIndex + '.jpg'),
            text: this.recognitionText[i]
          })
        }
      }
    },
    isIos() {
      const ua = navigator.userAgent.toLowerCase()
      const t1 =
        /iphone|ipad|ipod|blackberry|iemobile|opera mini|mac/i.test(
          ua
        )
      const t2 =
        ua.indexOf('windows') < 0 &&
        ua.indexOf('iphone') < 0 &&
        navigator.maxTouchPoints > 1
      return t1 || t2
    }
  }
}
</script>
<style scoped>
.bg-img {
  background: url('../assets/images/home.jpg') no-repeat;
  height: 100vh;
  opacity:0.6;
  filter: alpha(opacity=60);
  background-size: cover;
}
.bg-img-fixed {
    background-attachment: fixed;
  }
#home .mask {
  background: #000;
}
.bm-view {
  width: 100%;
  height: 250px;
}
.intro {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
}
li {
  list-style: none;
}
.box {
  height: 5px;
}
.service-text {
  line-height: 2.4rem;
  text-indent: 2rem;
}
.text-about {
  line-height: 2.4rem;
  letter-spacing: 0.2rem;
  text-indent: 2rem;
  font-size: 1.2rem;
}
.title-info {
  font-size: 15px;
  line-height: 2rem;
}
</style>
