import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/animate'
import './assets/iconfont/iconfont'
import './plugins/map'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import { Card, Button, Image } from 'element-ui'
Vue.use(Card)
Vue.use(Button)
Vue.use(Image)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
