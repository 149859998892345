<template>
  <b-container fluid="true">
    <transition-group
      name="list"
      class="carousel-box"
      tag="b-row"
    >
      <b-col
        v-for="item in activeList"
        :key="item.id"
        class="list-item px-3"
      >
        <slot name="body" :item="item" />
      </b-col>
    </transition-group>
  </b-container>
</template>

<script>
export default {
  name: 'Carousel',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    customClass: {
      type: String,
      default: () => ''
    },
    interval: {
      type: Number,
      default: () => 3000
    },
    lg: {
      type: Number,
      default: () => 4
    },
    md: {
      type: Number,
      default: () => 3
    },
    sm: {
      type: Number,
      default: () => 2
    },
    xs: {
      type: Number,
      default: () => 1
    }
  },
  data() {
    return {
      count: null,
      activeList: [],
      screenWidth: document.body.clientWidth
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: function() {
      const len = this.list.length

      if (this.screenWidth < 768) {
        this.count = this.xs
      } else if (this.screenWidth < 992) {
        this.count = this.sm
      } else if (this.screenWidth < 1200) {
        this.count = this.md
      } else {
        this.count = this.lg
      }

      let index = 0
      for (index; index < this.count; index++) {
        this.activeList.push(this.list[index])
      }
      setInterval(() => {
        if (index === len) {
          index = 0
        }
        this.activeList.shift()
        this.activeList.push(this.list[index])
        index++
      }, this.interval)
    }
  }
}
</script>

<style scoped>
.list-item {
  transition: all 1s;
  display: inline-block;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.list-leave-active {
  transition: 0s;
  position: fixed;
}
</style>
